import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BaiduMap from "vue-baidu-map-3x";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
const app = createApp(App);
app.use(ElementPlus);

app.use(BaiduMap, {
  ak: "ggxdmHdXMGzPsPoZfcHZCCGkbEMiyhdD",
});
app.use(store).use(router).mount("#app");
