import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: "/contract",
    name: 'contract',
    component: () => import('@/views/contract.vue')
  },
  {
    path: "/bottom",
    name: 'bottom',
    component: () => import('@/views/bottom.vue')
  },
  {
    path: "/safety",
    name: 'safety',
    component: () => import('@/views/safety.vue')
  },
  {
    path: "/butterfly",
    name: 'butterfly',
    component: () => import('@/views/butterfly.vue')
  },
  {
    path: "/gate",
    name: 'gate',
    component: () => import('@/views/gate.vue')
  },
  {
    path: "/ball",
    name: 'ball',
    component: () => import('@/views/ball.vue')
  },
  {
    path: "/globe",
    name: 'globe',
    component: () => import('@/views/globe.vue')
  },
  {
    path: "/plug",
    name: 'plug',
    component: () => import('@/views/plug.vue')
  },
  {
    path: "/exhaust",
    name: 'exhaust',
    component: () => import('@/views/exhaust.vue')
  },
  {
    path: "/pressure",
    name: 'pressure',
    component: () => import('@/views/pressure.vue')
  },
  {
    path: "/custom",
    name: 'custom',
    component: () => import('@/views/custom.vue')
  },
  {
    path: "/source",
    name: 'source',
    component: () => import('@/views/source.vue')
  },
  {
    path: "/professional",
    name: 'professional',
    component: () => import('@/views/professional.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
