<template>
  <div class="box">
    <div class="top fx-b">
      <!-- <div class="left">
				<h1>Professional valve manufacturer</h1>
				<button>Excellent material Fine workmanship Durable</button>
				<div class="fx-c icon">
					<div>
						<img src="../assets/img/Home/1/1.png" alt="" />
						<p>Adequate supply</p>
					</div>
					<div>
						<img src="../assets/img/Home/1/2.png" alt="" />
						<p>Support customization</p>
					</div>
				</div>
			</div>
			<div class="right fx-s">
				<img src="../assets/img/Home/1/3.png" alt="" />
				<img src="../assets/img/Home/1/4.png" alt="" />
			</div> -->
      <div class="block text-center" m="t-4">
        <!-- <span class="demonstration">Switch when indicator is clicked</span> -->
        <el-carousel trigger="click" height="570px">
          <el-carousel-item>
            <img src="../assets/img/Home/1/示例.jpg" alt="" />
          </el-carousel-item>
          <el-carousel-item>
            <img src="../assets/img/Home/2/示例.jpg" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="navbox w80">
      <img
        src="https://31911550.s21i.faiusr.com/2/1/ABUIABACGAAgqu-yrAYot8HU4AcwsAk4-AU.jpg.webp"
        style="width: 100%"
        alt=""
      />
      <!-- <h1>Hot products</h1>
			<hr />
			<div class="fx-c">
				<hr />
				<p>13 years of Valve manufacturing</p>
				<hr />
			</div>
			<nav class="fx-b">
				<div><img src="../assets/img/Home/1/4.png" alt="" /></div>
				<div><img src="../assets/img/Home/1/4.png" alt="" /></div>
				<div><img src="../assets/img/Home/1/4.png" alt="" /></div>
				<div><img src="../assets/img/Home/1/4.png" alt="" /></div>
				<div><img src="../assets/img/Home/1/4.png" alt="" /></div>
				<div><img src="../assets/img/Home/1/4.png" alt="" /></div>
				<div><img src="../assets/img/Home/1/4.png" alt="" /></div>
				<div><img src="../assets/img/Home/1/4.png" alt="" /></div>
				<div><img src="../assets/img/Home/1/4.png" alt="" /></div>
				<div><img src="../assets/img/Home/1/4.png" alt="" /></div>
			</nav> -->
    </div>
    <div class="factory w80">
      <h1>Factory strength</h1>
      <hr />
      <div class="grid">
        <div class="g1">
          <img src="../assets/img/Home/1.jpg" alt="" />
        </div>
        <div class="g2">
          <div class="fx-b">
            <div>
              <div class="text">
                <h1>Factory display</h1>
                <p>Good production order,true display of corporatestrength</p>
              </div>
              <img src="../assets/img/Home/2-l.jpg" alt="" />
            </div>
            <img src="../assets/img/Home/2-r.jpg" alt="" />
          </div>
        </div>
        <div class="g3">
          <div class="text">
            <h1>Well equipped</h1>
            <p>
              Complete processing equipment,high processing precision and
              morematuretechnology
            </p>
          </div>
        </div>
        <div class="g4">
          <img src="../assets/img/Home/4.jpg" alt="" />
        </div>
        <div class="g5">
          <img src="../assets/img/Home/5.jpg" alt="" />
        </div>
        <div class="g6">
          <div class="text">
            <h1>Shipment site</h1>
            <p>
              Order shipped on thesame day, withmultiple logistics and express
              deliverymodes to serve you
            </p>
          </div>
        </div>
        <div class="g7">
          <img src="../assets/img/Home/7.jpg" alt="" />
        </div>
        <div class="g8">
          <div class="text">
            <h1>Factory inventoryreal photos</h1>
            <p>
              Suficient inventory fromthe source manufacturerlargequantity,
              andpreferentialtreatment
            </p>
          </div>
        </div>
        <div class="g9">
          <img src="../assets/img/Home/9.jpg" alt="" />
        </div>
      </div>
    </div>
    <div class="henan w80">
      <div class="title">
        <h1>Henan Jitong Valve Co., Ltd.</h1>
        <span></span>
      </div>
      <div class="fx-e">
        <div class="img">
          <img src="../assets/img/About/1.jpg" alt="" />
        </div>
        <div>
          <h1>ABOUT US</h1>
          <p>
            Henan Jitong Valve Co., Ltd, is located in Xingyang,the "Hometown of
            Valve inChina", with strong technical force, sophisticated
            production equipment andcomplete testing methods, lt has the ability
            to independently develop andproduce large-diameter valves. lts main
            products include: triple eccentricall-metal seal butterfly valve,
            double eccentric semi-ball valve, quick shut-offvalve,
            high-performance anti-surge valve, water-sealed gas shut-off
            valve.plug valve, blind plate valves, gate valves, globe valves,
            check valves, ballvalves and non-non-gas shut-off valves, standard
            valves and several otherproducts.
          </p>
          <big>More></big>
        </div>
      </div>
    </div>
    <!-- <footer>
			<div class="fx-b">
				<div>
					<h3>Email:</h3>
					<h2>itvalve@veah.net</h2>
					<small>Welcome to contact us</small>
				</div>
				<div>
					<h3>Contact information:</h3>
					<h2>+86-173-3575-5575</h2>
					<small>Wechat/whats app</small>
				</div>
			</div>
		</footer> -->
  </div>
</template>
<style lang="scss" scoped>


/* 在屏幕宽度小于999像素时修改样式 */
@media only screen and (max-width: 999px) {


  // :deep(.fx-b) {
  //   width: 1200px !important;
  // }

  :deep(.box .top .block[data-v-9ea40744]) {
    width: 1200px !important;
  }

  :deep(.el-carousel--horizontal) {
    width: 1200px !important;
  }
  :deep(.el-carousel--horizontal img) {
    width: 1200px !important;
  }
}

.box {
  .top {
    // background: url("../assets/img/Home/1/bg.png") no-repeat;
    // background-size: 100%, 100%;
    // > div {
    //   width: 50%;
    // }
    // .left {
    //   height: 100%;
    //   text-align: center;
    //   h1 {
    //     margin-bottom: 70px;
    //     font-size: 50px;
    //     color: #294994;
    //   }
    //   button {
    //     position: relative;
    //     border: none;
    //     color: #fff;
    //     background-color: #294994;
    //     padding: 10px 10px;
    //     border-radius: 50px;
    //   }
    //   button::after {
    //     g2: "";
    //     position: absolute;
    //     left: 0;
    //     top: -50%;
    //     height: 2px;
    //     width: 100%;
    //     background-color: #000;
    //   }
    //   > div {
    //     > div {
    //       margin: 30px;
    //       position: relative;
    //       img {
    //         width: 50px;
    //         display: block;
    //         margin: auto;
    //       }
    //       p {
    //         height: 30px;
    //         line-height: 30px;
    //         position: relative;
    //         z-index: 999;
    //       }
    //     }
    //     > div::after {
    //       content: "";
    //       position: absolute;
    //       left: 50%;
    //       bottom: 3px;
    //       height: 10px;
    //       width: 50%;
    //       border-radius: 10px;
    //       transform: translateX(-50%);
    //       background-color: #a4b5e1;
    //     }
    //   }
    // }
    // .right {
    //   justify-content: center;
    //   align-items: flex-end;
    //   img {
    //     width: 50%;
    //   }
    //   img:nth-child(1) {
    //     width: 60%;
    //   }
    //   img:nth-child(2) {
    //     margin-left: -30%;
    //   }
    // }
    .block {
      width: 100%;
      height: 570px;
   
      img {
		display: block;
		margin: auto;
        // height: 570px;
        height: 456px;
        // height: 100%;
		aspect-ratio: 24/9;
      }
    }
  }
  .navbox {
    // background-color: rgb(231, 231, 231);
    text-align: center;
    padding: 20px 0;
    h1 {
      padding: 10px 0;
      font-size: 50px;
      color: #294994;
    }
    > hr {
      width: 10%;
      display: block;
      height: 2px;
      background-color: #000;
      border-radius: 2px;
      margin: 10px auto;
    }
    > div {
      > hr {
        width: 30%;
        margin: 0 10px;
      }
    }
    > nav {
      width: 90%;
      margin: 20px auto;
      flex-wrap: wrap;
      > div {
        width: 17%;
        margin: 30px 10px;
        background: url("../assets/img/Home/热销/1.png") no-repeat;
        background-size: 100% 100%;
        img {
          width: 100%;
        }
      }
    }
  }
  .factory {
    text-align: center;
    margin-bottom: 20px;
    h1 {
      padding: 10px 0;
      font-size: 50px;
      color: #294994;
    }
    > hr {
      width: 10%;
      display: block;
      height: 2px;
      background-color: #000;
      border-radius: 2px;
      margin: 10px auto;
    }
    .grid {
      box-sizing: border-box;
      border-left: 10px solid #294994;
      border-right: 10px solid #294994;
      border-bottom: 5px solid #294994;
      margin-top: 50px;

      overflow: hidden;
      width: 100%;
      // height: 600px;
      aspect-ratio: 16/11;
      display: grid;
      // grid-gap: 1px;
      grid-template-columns: 45% 25% 30%;
      grid-template-areas:
        "g1 g3 g7"
        "g1 g3 g7"
        "g1 g4 g7"
        "g1 g4 g7"
        "g2 g4 g8"
        "g2 g5 g8"
        "g2 g5 g9"
        "g2 g5 g9"
        "g2 g6 g9"
        "g2 g6 g9";
      background-color: #fff;
      color: #444;
      > div {
        .fx-b {
          height: 100%;
          align-items: flex-start;
          > div,
          > img {
            height: 100%;
            width: 50% !important;
          }
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .text {
        h1 {
          font-size: 30px;
        }
        p {
          margin: 0 10px;
        }
      }
      .g1 {
        grid-area: g1;
      }

      .g2 {
        grid-area: g2;
      }
      .g3 {
        grid-area: g3;
      }

      .g4 {
        grid-area: g4;
      }
      .g5 {
        grid-area: g5;
      }
      .g6 {
        grid-area: g6;
      }
      .g7 {
        grid-area: g7;
      }
      .g8 {
        grid-area: g8;
      }
      .g9 {
        grid-area: g9;
      }
    }
  }
  .henan {
    background: url("../assets/img/Home/bg.png") no-repeat;
    background-size: 100%, 100%;
    background-color: #095fbf;
    padding-bottom: 50px;
    overflow: hidden;
    color: #fff;
    text-align: center;

    .title {
      z-index: 10;
      width: calc(70% + 20px);
      text-align: left;
      margin: auto;
      margin-top: 50px;
      // position: relative;
      overflow: hidden;
      h1 {
        float: left;
        // position: relative;
        margin: 10px;
        display: inline-block;
        background-color: #095fbf;
        padding-right: 50px;
        z-index: 10;
        clip-path: polygon(0% 0%, 90% 0%, 100% 100%, 0% 100%);
      }
      span {
        float: left;
        display: block;
        // position: absolute;
        width: 55px;
        height: 37px;
        margin-top: 10px;
        margin-left: -40px;
        z-index: 9999;
        background-color: #095fbf;
        clip-path: polygon(0% 0%, 20% 0%, 100% 100%, 80% 100%);
      }
    }
    > h1 {
      position: relative;
      color: #d0d0d0;
      font-size: 50px;
      margin: 20px 0;
      z-index: 10;
    }
    .fx-c {
      position: relative;
      z-index: 10;
      height: 300px;
      p {
        background-color: #095fbf;
        border-radius: 30px;
        padding: 5px 10px;
        margin: 0 10px;
      }
    }
    .fx-e {
      align-items: flex-start;
      justify-content: center;
      .img {
        width: 30%;
        box-sizing: border-box;
        padding: 20px 0;
        margin-right: 20px;
        background-color: #095fbf;
        img {
          width: 100%;
        }
      }
      > div:nth-last-child(1) {
        width: 40%;
        text-align: left;
        color: #000;
        padding: 10px 20px;
        box-sizing: border-box;
        background-color: #fff;
        > h1 {
          margin: 10px 0;
        }
        > p {
          line-height: 25px;
          font-size: 10px;
          // word-break: break-all;
        }
        big {
          background-color: #095fbf;
          color: #fff;
          padding: 5px 10px;
        }
      }
    }
  }
  // footer {
  // 	background-color: #0a3085;
  // 	color: #fff;
  // 	padding: 20px 0;
  // 	> div {
  // 		justify-content: center;
  // 		border: 1px solid #fff;
  // 		width: 50%;
  // 		margin: auto;
  // 		padding: 10px 0;
  // 		> div {
  // 			padding: 0 10px;
  // 			h2 {
  // 				color: #d59e1d;
  // 			}
  // 		}

  // 		> div:nth-child(1) {
  // 			text-align: right;
  // 			border-right: 1px solid #fff;
  // 		}
  // 	}
  // }
}
</style>
