<template>
  <div class="header">
    <div class="w80 fx-b">
      <h1 id="two">Henan Jitong Valve Co., Ltd </h1>
      <div class="fx-b" id="one">
        <img src="https://16651405.s61i.faiusr.com/4/AD0Ijan4BxAEGAAg55mQ1wUo3sOqEjAwODA.png" alt="" />
        <div>
          <!-- <p>产品咨询热线：</p> -->
          <h1 ><i>+86-173-3575-5575</i></h1>
        </div>
      </div>
    </div>
    <div class="search">
      <div class="searchs w80 fx-b">
        <nav class="fx-t">
          <router-link :class="route.path == '/' ? 'gl' : ''" to="/"> Home</router-link>
          <router-link :class="route.path == '/professional' ? 'gl' : ''" to="/professional"> Product</router-link>
          <!-- <router-link :class="route.path=='/safety'?'gl':''"  to="/safety"> 商品详情</router-link> -->
          <router-link :class="route.path == '/source' ? 'gl' : ''" to="/source"> About us</router-link>
          <router-link :class="route.path == '/contract' ? 'gl' : ''" to="/contract"> Contract us</router-link>
        </nav>
        <div class="fx-t">
          <input type="text" />
          <button>search</button>
        </div>
      </div>
    </div>
  </div>
  <router-view />
  <footer class="footerbox">
    <div class="fx-b w80">

      <div>
        <h3>Email:</h3>
        <h1>jtvalve@yeah.net</h1>
        <small>Welcome to contact us</small>
      </div>

      <div>
        <h3>Contact information:</h3>
        <h1>+86-173-3575-5575</h1>
        <small>Wechat/whats app</small>
      </div>

    </div>
  </footer>
  <!-- <footer class="footerbox">
    <div class="footbox w80 fx-s">
      <div>
        <h3>关于我们:</h3>
        <p>
          我们专业销售世界各国原装轴承。产品主要适用于：航天、铁路、汽车、机床、港口、电机、石化、制药、纺织、印刷、木工、水电及各种机械制造的配套与维修专用轴承。
        </p>
      </div>
      <div>
        <h3>联系我们:</h3>
        <p>联系邮箱：xxx@.co.m</p>
        <p>联系电话：020-000000 000-000000</p>
        <p>联系地址：XXX省XXX市XXX县XXX路XXX号</p>
      </div>
      <div class="fx-s">
        <h3>我们的二维码:</h3>
        <img
          src="https://kv29465886-1.jzfkw.net/qrCode.jsp?cmd=mobiQR&_s=148&lanCode=&foreColor=%23000000&qrCodeIconID="
          alt=""
        />
      </div>
    </div>
    <p>©2024 - 功率计划 版权所有</p>
    <p>本站使用凡科建站搭建</p>
  </footer> -->
</template>

<script setup>
import { useRoute } from 'vue-router';
const route = useRoute()
console.log(route);
</script>

<style lang="scss">
/* 在屏幕宽度小于999像素时修改样式 */
@media only screen and (max-width: 999px) {
  .footerbox {
    width: 1200px !important;

    /* 添加其他针对小屏幕的样式 */
  }

  #one {
    margin-right: 45px !important;
  }

  #two {
    margin-left: 35px !important;
  }

  .header {
    width: 1200px !important;
  }

  :deep(.fx-b) {
    width: 1200px !important;
  }

  :deep(.box .top .block[data-v-9ea40744]) {
    width: 1200px !important;
  }

  :deep(.el-carousel--horizontal) {
    width: 1200px !important;
  }

}

* {
  padding: 0;
  margin: 0
}

html,
body,
#app {
  height: 100%;
  width: 100%;
}

.gl {
  color: #fff !important;
}

.header {
  width: 100%;
  padding-top: 20px;
  color: #ffff;
  background: transparent url(//16651405.s61i.faiusr.com/4/AD0Ijan4BxAEGAAgy5iQ1wUo8Ja2gAUwgA84yAE.png) repeat top;

  >.w80 {
    padding-bottom: 20px;
  }

  .search {
    background-color: #1b2f47;

    .searchs {
      nav {
        width: 50% !important;

        a {
          box-sizing: border-box;
          color: #ccc;
          padding: 20px 20px;
        }

        a:hover {
          color: #fff;
        }
      }

      .fx-t {
        width: 30%;

        input {
          height: 35px;
          line-height: 35px;
          border: none;
          width: 70%;
        }

        button {
          color: #fff;
          background-color: #cccc;
          border: none;
          height: 35px;
          line-height: 35px;
          padding: 0 20px;
        }
      }
    }
  }
}

.box {
  //   width: 1920px;
  // width: 1200px;
  // margin: auto;
  width: 100%;
}

.footerbox {
  background-color: #0a3085;
  color: #fff;
  padding: 20px 0;

  >div {
    justify-content: center;
    border: 1px solid #fff;
    // width: 50%;
    width: 950px;
    height: 170px;
    margin: auto;
    padding: 10px 0;

    >div {

      padding: 0 10px;

      h3,
      h1,
      small {
        height: 50px;
        line-height: 50px;
      }

      h1 {
        color: #d59e1d;
        font-size: 38px;

      }
    }

    >div:nth-child(1) {
      text-align: right;
      border-right: 1px solid #fff;
    }
  }
}

// .footerbox{
//   background-color: #1b2f47;
//   color: #516678;
//   padding-top: 50px;
//   .footbox{
//     >div{
//       width: 30%;
//       h3{
//         color: #fff;
//       }
//       p{
//         line-height: 50px;
//       }
//     }
//   }
//   >p{
//     text-align: center;
//     color: #b3b3b3;
//   }
// }
* {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  vertical-align: middle;
}

input {
  outline: none;
}

body {
  font-family: "微软雅黑", "Arial Narrow", Arial, sans-serif;
  font-size: 14px;
}

.flexbetweenstart {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.flexbetweencenter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexbetweencenter img {
  margin: 0 3px;
  box-sizing: border-box;
  border: 1px solid #aaa;
}

.flexevenlycenter {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.flexcenter {
  display: flex;
  align-items: center;
}

html,
body {
  width: 100%;
  height: 100%;
}

.w80 {
  width: 1200px;
  display: block;
  margin: auto;
}

.f-right {
  float: right;
}

.fx-b {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fx-c {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fx-e {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.fx-s {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.fx-t {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.b {
  border: 1px solid #aaaa;
}

.red {
  color: red;
}
</style>



